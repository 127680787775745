import React from 'react';
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout';
import NotFoundPage from '../pages/404';
import { WorldsFairHead } from '../components/Head';

const Mission = ({ data: { mission } }: PageProps<Queries.MissionTemplateQuery>) => {
    if (!mission) {
        return <NotFoundPage />;
    }

    const {
        title,
        tghpworldsfairQuoteText: quoteText,
        tghpworldsfairQuoteSource: quoteSource,
        tghpworldsfairPrimaryContent: primaryContent,
    } = mission;

    return (
        <Layout location="mission" showSignup={true} seoData={mission.seo}>
            <h1>{title}</h1>
            <hr className="line" />
            {quoteSource &&
                <div className="quote">
                    <div className="quote__text">{quoteText}</div>
                    <div className="quote__source">{quoteSource}</div>
                </div>
            }
            <article>
                {primaryContent && primaryContent.split('\n').map((contentParagraph, i) =>
                    <p key={i}>{contentParagraph}</p>
                )}
            </article>
        </Layout>
    );
}

export default Mission;

export const query = graphql`
    query MissionTemplate ($id: Int!) {
        mission: wpPage(databaseId: { eq: $id }) {
            title
            tghpworldsfairQuoteText
            tghpworldsfairQuoteSource
            tghpworldsfairPrimaryContent
            ...SeoData
        }
    }
`

export const Head = () => <WorldsFairHead />